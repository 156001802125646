import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import ProjectCard from "../../components/project-card"
import Versed from "../../images/projects/versed/cover.jpg"
import IsaLazo from "../../images/projects/isa-lazo/cover.jpg"

const aurisPage = () => (
  <Layout>
    <SEO title="Relevant Skin" />
    <WorkInfo 
      title="Relevant Skin"
      link="relevantskin.com"
      team1="Bailey Latimer"
      team2="Noel Reinhold"
      challenge="Relevent Skin is a fresh brand in the skincare industry. They wanted to embody transparency and inclusivity through every touchpoint - yet make it fun and simple."
      solution="We shot assets of diverse models in-house for inclusivity. Next, we created a holistic web experience with vibrant colors that communicated a playful, organic mood. We also implemented metafields so the content team could easily update information."/>

    <StaticImage class="mb-6" src="../../images/projects/relevant-skin/cover.jpg" alt="Relevant Skin Hero" aspectRatio={16/7} layout={"fullWidth"}/>

      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/relevant-skin/browser.jpg" alt="Relevant Skin Browser Preview" layout={"fullWidth"} aspectRatio={4/5}/>
        <StaticImage class="mb-6" src="../../images/projects/relevant-skin/home--about.jpg" alt="Relevant Skin Home Reviews" layout={"fullWidth"} aspectRatio={4/5}/>
      </div>
      <StaticImage class="mb-6" src="../../images/projects/relevant-skin/home--desktop.jpg" alt="Relevant Skin Home Preview" layout={"fullWidth"}/>

      <StaticImage class="mb-20" src="../../images/projects/relevant-skin/about--desktop.jpg" alt="Relevant Skin About Page" layout={"fullWidth"}/>

      <div class="adv-gallery-v3 mb-20">
        <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
        <ul class="columns-1 lg:columns-2">
        <ProjectCard classes="" title="Versed Skin" url="versed-skin"  description="Revolutionizing the natural skincare industry with all-natural, vegan ingredients for cleansers, moisturizers, serums, masks." cover={Versed} role="Development" />

        <ProjectCard classes="" title="Isa Lazo" url="isa-lazo"  description="Clean skincare crafted from the purest elements of nature." cover={IsaLazo}  role="Development"/>
        </ul>
      </div>

  </Layout>
)

export default aurisPage
